import React from "react"
import { Router } from "@reach/router"
import { useIntl, getLanguagePathPrefix } from "../utils"
import { Layout, SEO, Protected } from "../components"
import { NewSiteFromTemplate } from "../containers"
const Page = () => {
  const intl = useIntl()
  const languagePathPrefix = getLanguagePathPrefix(intl)
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "hello" })} />
      <Router basepath={`${languagePathPrefix}/templates`}>
        <Protected
          path="/:templateId/generate"
          component={NewSiteFromTemplate}
        ></Protected>
      </Router>
    </Layout>
  )
}
export default Page
